* {
    padding: 0;
    margin: 0;
    box-sizing: border-box !important;
}
header.tituloProductos {
    text-align: center;
    padding: 20px 0;
    font-family: "Antonio";
    font-size: 60px;
    color: #FD5D05;
    font-weight: bold;
}
h1.subTituloProductos {
    padding: 20px 10px;
    color: white;
    font-family: "Antonio";
    font-weight: bold;
    font-size: 45px;
    padding-bottom: 0;
    margin: 0;
}
.contenedor-cartitas {
    height: auto;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-wrap: wrap;
    gap: 20px;
}
.cartita, .cartitaConImagen {
    width: 40%;
    min-height: 400px;
    color: white;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    outline: 2px solid #FD5D05;
    transition: all ease-in-out 0.5s;
    -webkit-transition: all ease-in-out 0.5s;
    -moz-transition: all ease-in-out 0.5s;
    -ms-transition: all ease-in-out 0.5s;
    -o-transition: all ease-in-out 0.5s;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    padding: 30px;
}
.cartitaConImagen img {
    width: 100%;
}
.cartita:hover, .cartitaConImagen:hover {
    background-color: #FD5D05;
    outline: 2px solid white;
}

/* Media Querys */
/* Titulos */
/* Medio pequeño (para teléfonos móviles grandes) */
@media only screen and (min-width: 481px) and (max-width: 600px) {
    header.tituloProductos {
        font-size: 60px;
    }
    h1.subTituloProductos {
        font-size: 30px;
    }
}
/* Pequeño (para teléfonos móviles pequeños) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
    header.tituloProductos {
        font-size: 50px;
    }
    h1.subTituloProductos {
        font-size: 30px;
    }
}
/* Extra pequeño (para teléfonos móviles muy pequeños) */
@media only screen and (max-width: 320px) {
    header.tituloProductos {
        font-size: 40px;
    }
    h1.subTituloProductos {
        font-size: 20px;
    }
}

/* Contenedor Cartas */
/* Extra pequeño (para teléfonos móviles muy pequeños) */
@media only screen and (min-width: 1201px) {
    .contenedor-cartitas div:nth-child(9) {
        min-height: 800px;
    }
}
/* Grande (para ordenadores de escritorio grandes) */
@media only screen and (min-width: 993px) and (max-width: 1200px) {
    .contenedor-cartitas div:nth-child(9) {
        min-height: 860px;
    }
}
/* Medio grande (para tabletas en paisaje y algunos ordenadores de escritorio) */
@media only screen and (min-width: 769px) and (max-width: 992px) {
    .contenedor-cartitas .cartita {
        min-height: 700px;
    }
    .contenedor-cartitas div:nth-child(9) {
        min-height: 1000px;
    }
}
/* Medio (para retratos de tabletas y teléfonos móviles grandes) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .contenedor-cartitas .cartita {
        min-height: 750px;
        width: 45%;
    }
    .contenedor-cartitas .cartitaConImagen {
        width: 45%;
    }
    .contenedor-cartitas div:nth-child(9) {
        min-height: 1150px;
    }
}
/* Medio pequeño (para teléfonos móviles grandes) */
@media only screen and (min-width: 481px) and (max-width: 600px) {
    .contenedor-cartitas .cartita {
        min-height: 620px;
        width: 48%;
    }
    .contenedor-cartitas .cartita p, .contenedor-cartitas .cartitaConImagen p {
        font-size: 14px;
    }
    .contenedor-cartitas .cartitaConImagen {
        width: 48%;
    }
    .contenedor-cartitas div:nth-child(9) {
        min-height: 930px;
    }
}
/* Pequeño (para teléfonos móviles pequeños) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
    .contenedor-cartitas .cartita {
        min-height: 400px;
        width: 100%;
    }
    .contenedor-cartitas .cartita p, .contenedor-cartitas .cartitaConImagen p {
        font-size: 14px;
    }
    .contenedor-cartitas .cartitaConImagen {
        width: 100%;
    }
    .contenedor-cartitas div:nth-child(9) {
        min-height: 450px;
    }
}
/* Extra pequeño (para teléfonos móviles muy pequeños) */
@media only screen and (max-width: 320px) {
    .contenedor-cartitas .cartita {
        min-height: 150px;
        width: 100%;
    }
    .contenedor-cartitas .cartita p, .contenedor-cartitas .cartitaConImagen p {
        font-size: 12px;
    }
    .contenedor-cartitas .cartitaConImagen {
        width: 100%;
    }
    .contenedor-cartitas div:nth-child(9) {
        min-height: 450px;
    }
}
